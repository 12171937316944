import { actionServiceTypeAddTypes } from 'constants/actionServiceTypeAddTypes';

export const setShowErrors = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_SHOW_ERRORS, payload });

export const setLabel = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_LABEL, payload });

export const setMetaDescription = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_META_DESCRIPTION, payload });

export const setSlug = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_SLUG, payload });

export const setShowLoading = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_SHOW_LOADING, payload });

export const setShowMainError = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_SHOW_MAIN_ERROR, payload });

export const setStatus = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_STATUS, payload });

export const setMinPrice = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_MIN_PRICE, payload });

export const setMinTime = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_MIN_TIME, payload });

export const setBasePercent = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_BASE_PERCENT, payload });

export const setBasePercentChoose = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_BASE_PERCENT_CHOOSE, payload });

export const setServiceTypeData = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_SERVICE_TYPE_DATA, payload });

export const setH1 = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_H1, payload });

export const setPageTitle = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_PAGE_TITLE, payload });

export const setContent = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_CONTENT, payload });

export const setSlugPrice = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_SLUG_PRICE, payload });

export const setMetaDescriptionPrice = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_META_DESCRIPTION_PRICE, payload });

export const setH1Price = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_H1_PRICE, payload });

export const setPageTitlePrice = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_PAGE_TITLE_PRICE, payload });

export const setContentPrice = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_CONTENT_PRICE, payload });

export const setParent = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_PARENT, payload });

export const setIconImage = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_ICON_IMAGE, payload });

export const setIconImageFile = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_ICON_IMAGE_FILE, payload });

export const setShowIconImageError = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_SHOW_ICON_IMAGE_ERROR, payload });

export const setInnerSlug = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_INNER_SLUG, payload });

export const setShowInnerSlugError = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_SHOW_INNER_SLUG_ERROR, payload });