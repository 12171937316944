import { createContext, useContext, useMemo, useReducer } from 'react';
import { initialState, seoOrderPageAddReducer } from './reducers/SeoOrderPageAddReducer';
import useSeoOrderPageAddActions from 'hooks/useSeoOrderPageAddActions';

const seoOrderPageAddContext = createContext({
    state: initialState,
    actions: {},
});

const useSeoOrderPageAddContext = () => {
    const context = useContext(seoOrderPageAddContext);

    if (!context) {
        throw new Error(
            'seoOrderPageAddContext must be used within a SeoOrderPageAddProvider.'
        );
    }

    return context;
};

const SeoOrderPageAddProvider = ({ showLoading, children }) => {

    // set loading
    if(showLoading){
        initialState.showLoading = showLoading;
    }

    const [seoOrderPageAddState, dispatch] = useReducer(seoOrderPageAddReducer, initialState);
    const actions = useSeoOrderPageAddActions(dispatch);
    const value = useMemo(() => ({ seoOrderPageAddState, actions }), [seoOrderPageAddState, actions]);
    console.log('🚀 ~ SeoOrderPageAddProvider ~ value:', value);

    return (
        <seoOrderPageAddContext.Provider value={value}>
            {children}
        </seoOrderPageAddContext.Provider>
    );
};

export { useSeoOrderPageAddContext, SeoOrderPageAddProvider };