import {Navigate, Outlet, useParams} from "react-router-dom";

const PageRoute = ({ navigateRoute }) => {
    const { page } = useParams();

    if(!isStringInteger(page)){
        return <Navigate to={navigateRoute} />
    }

    return <Outlet />;
};

function isStringInteger(str) {
    const parsed = parseInt(str, 10); // Convert string to integer
    return !isNaN(parsed) && Number.isInteger(parsed) && parsed.toString() === str;
}

export default PageRoute;