export const actionSeoOrderPageAddTypes = {
    SET_SHOW_LOADING: 'SET_SHOW_LOADING',
    SET_STATUS: 'SET_STATUS',
    SET_SLUG: 'SET_SLUG',
    SET_SHOW_ERRORS: 'SET_SHOW_ERRORS',
    SET_PAGE_TITLE: 'SET_PAGE_TITLE',
    SET_META_DESCRIPTION: 'SET_META_DESCRIPTION',
    SET_H1: 'SET_H1',
    SET_CONTENT: 'SET_CONTENT',
    SET_SHOW_MAIN_ERROR: 'SET_SHOW_MAIN_ERROR',
    SET_SEO_ORDER_PAGE_DATA: 'SET_SEO_ORDER_PAGE_DATA',
    SET_SERVICE_TYPE: 'SET_SERVICE_TYPE',
    SET_SHOW_SERVICE_TYPE_ERROR: 'SET_SHOW_SERVICE_TYPE_ERROR',
    SET_SERVICE: 'SET_SERVICE',
    SET_SERVICES_TO_CHOOSE: 'SET_SERVICES_TO_CHOOSE',
};