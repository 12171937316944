import { createContext, useContext, useMemo, useReducer } from 'react';
import { initialState, partnerAddReducer } from './reducers/PartnerAddReducer';
import usePartnerAddActions from 'hooks/usePartnerAddActions';

const partnerAddContext = createContext({
    state: initialState,
    actions: {},
});

const usePartnerAddContext = () => {
    const context = useContext(partnerAddContext);

    if (!context) {
        throw new Error(
            'useOrderContext must be used within a OrderProvider.'
        );
    }

    return context;
};

const PartnerAddProvider = ({ showLoading, children }) => {

    // set loading
    if(showLoading){
        initialState.showLoading = showLoading;
    }
    
    const [partnerAddState, dispatch] = useReducer(partnerAddReducer, initialState);
    const actions = usePartnerAddActions(dispatch);
    const value = useMemo(() => ({ partnerAddState, actions }), [partnerAddState, actions]);
    console.log('🚀 ~ PartnerAddProvider ~ value:', value);

    return (
        <partnerAddContext.Provider value={value}>
            {children}
        </partnerAddContext.Provider>
    );
};

export { usePartnerAddContext, PartnerAddProvider };