import { actionServiceTypeAddTypes } from 'constants/actionServiceTypeAddTypes';

export const initialState = {
    id: null,
    showLoading: false,
    showMainError: false,
    showIconImageError: false,
    parent: null,
    label: {},
    slug: {},
    status: 1,
    minPrice: 0,
    minTime: 0,
    basePercent: 50,
    basePercentChoose: 52,
    errors: {},
    h1: {},
    metaDescription: {},
    pageTitle: {},
    content: {},
    slugPrice: {},
    h1Price: {},
    metaDescriptionPrice: {},
    pageTitlePrice: {},
    contentPrice: {},
    iconImage: null,
    iconImageFile: null,
    innerSlug: null,
    showInnerSlugError: false,
};

export const serviceTypeAddReducer = (state, action) => {
    switch (action.type) {
        default:
            return state;
        case actionServiceTypeAddTypes.SET_INNER_SLUG:
            return {
                ...state,
                innerSlug: action.payload,
            };
        case actionServiceTypeAddTypes.SET_SHOW_INNER_SLUG_ERROR:
            return {
                ...state,
                showIconImageError: action.payload,
            }
        case actionServiceTypeAddTypes.SET_LABEL:
            return {
                ...state,
                label: {
                    ...state.label,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceTypeAddTypes.SET_SLUG:
            return {
                ...state,
                slug: {
                    ...state.slug,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceTypeAddTypes.SET_META_DESCRIPTION:
            return {
                ...state,
                metaDescription: {
                    ...state.metaDescription,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceTypeAddTypes.SET_ICON_IMAGE:
            return { ...state, iconImage: action.payload };
        case actionServiceTypeAddTypes.SET_ICON_IMAGE_FILE:
            return { ...state, iconImageFile: action.payload };
        case actionServiceTypeAddTypes.SET_SHOW_ICON_IMAGE_ERROR:
            return { ...state, showIconImageError: action.payload };
        case actionServiceTypeAddTypes.SET_SHOW_LOADING:
            return { ...state, showLoading: action.payload };
        case actionServiceTypeAddTypes.SET_MIN_PRICE:
            return { ...state, minPrice: action.payload };
        case actionServiceTypeAddTypes.SET_MIN_TIME:
            return { ...state, minTime: action.payload };
        case actionServiceTypeAddTypes.SET_BASE_PERCENT:
            return { ...state, basePercent: action.payload };
        case actionServiceTypeAddTypes.SET_BASE_PERCENT_CHOOSE:
            return { ...state, basePercentChoose: action.payload };
        case actionServiceTypeAddTypes.SET_STATUS:
            return { ...state, status: action.payload };
        case actionServiceTypeAddTypes.SET_SHOW_MAIN_ERROR:
            return { ...state, showMainError: action.payload };
        case actionServiceTypeAddTypes.SET_PARENT:
            return { ...state, parent: state.parent === action.payload ? null : action.payload };
        case actionServiceTypeAddTypes.SET_SHOW_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.language]: {
                        ...state.errors[action.payload.language],
                        [action.payload.type]: action.payload.value,
                    },
                },
            };
        case actionServiceTypeAddTypes.SET_SERVICE_TYPE_DATA:
            return {
                ...state,
                id: action.payload.id,
                status: action.payload.status,
                innerSlug: action.payload.innerSlug,
                parent: action.payload.parent,
                minPrice: action.payload.minPrice,
                minTime: action.payload.minTime,
                basePercent: action.payload.basePercent,
                basePercentChoose: action.payload.basePercentChoose,
                label: action.payload.labelByLanguage,
                slug: action.payload.slugByLanguage,
                metaDescription: action.payload.metaDescriptionByLanguage,
                content: action.payload.contentByLanguage,
                h1: action.payload.h1ByLanguage,
                pageTitle: action.payload.pageTitleByLanguage,
                slugPrice: action.payload.slugPriceByLanguage,
                metaDescriptionPrice: action.payload.metaDescriptionPriceByLanguage,
                contentPrice: action.payload.contentPriceByLanguage,
                h1Price: action.payload.h1PriceByLanguage,
                pageTitlePrice: action.payload.pageTitlePriceByLanguage,
            };
        case actionServiceTypeAddTypes.SET_SLUG_PRICE:
            return {
                ...state,
                slugPrice: {
                    ...state.slugPrice,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceTypeAddTypes.SET_PAGE_TITLE:
            return {
                ...state,
                pageTitle: {
                    ...state.pageTitle,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceTypeAddTypes.SET_PAGE_TITLE_PRICE:
            return {
                ...state,
                pageTitlePrice: {
                    ...state.pageTitlePrice,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceTypeAddTypes.SET_CONTENT:
            return {
                ...state,
                content: {
                    ...state.content,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceTypeAddTypes.SET_CONTENT_PRICE:
            return {
                ...state,
                contentPrice: {
                    ...state.contentPrice,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceTypeAddTypes.SET_H1:
            return {
                ...state,
                h1: {
                    ...state.h1,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceTypeAddTypes.SET_H1_PRICE:
            return {
                ...state,
                h1Price: {
                    ...state.h1Price,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceTypeAddTypes.SET_META_DESCRIPTION_PRICE:
            return {
                ...state,
                metaDescriptionPrice: {
                    ...state.metaDescriptionPrice,
                    [action.payload.language]: action.payload.value,
                },
            };
    }
};