import {
    createContext,
    useContext,
    useMemo,
    useReducer,
} from 'react';

import { initialState, orderReducer } from './reducers/OrderReducer';
import useOrderActions from 'hooks/useOrderActions';

const orderContext = createContext({
    state: initialState,
    actions: {},
});

const useOrderContext = () => {
    const context = useContext(orderContext);

    if (!context) {
        throw new Error(
            'useOrderContext must be used within a OrderProvider.'
        );
    }

    return context;
};

const OrderProvider = ({ children, orderData }) => {
    const initialStateWithOrderData = orderData
        ? {
            ...initialState,
            id: orderData.id,
            labelId: orderData.labelId,
            date: orderData.date,
            time: orderData.time,
            channel: orderData.channel,
            description: orderData.description,
            numberOfPartners: orderData.numberOfPartners,
            address: orderData.address,
            serviceTypesLabels: orderData.serviceTypesLabels,
            latitude: orderData.latitude,
            longitude: orderData.longitude,
            partnersChooseByCustomer: orderData.partnersChooseByCustomer,
            serviceTypes: orderData.serviceTypes,
            isFullPartners: orderData.isFullPartners,
            isUpdated: orderData.isUpdated,
            price: orderData.price,
            priceFull: orderData.priceFull,
            labelsPartner: orderData.labelsPartner,
            labelsAdmin: orderData.labelsAdmin,
            messages: [],
            labels: orderData.labels,
            partnerCashName: orderData.partnerCashName,
            cleaningTime: orderData.cleaningTime,
            periodicity: orderData.periodicity,
            discount: orderData.discount,
            discountValue: orderData.discountValue,
            discountType: orderData.discountType,
            paymentMethodId: orderData.paymentMethodId,
            paymentMethodLabel: orderData.paymentMethodLabel,
            paymentStatus: orderData.paymentStatus,
            customer: orderData.customer,
            status: orderData.status,
            isLoading: false,
            dateMonth: orderData.dateMonth,
            dateYear: orderData.dateYear,
            idServiceType: orderData.idServiceType,
            dateFormated: orderData.dateFormated,
            timeAny: orderData.timeAny,
            timeSpecify: orderData.timeSpecify,
            needInvoice: orderData.needInvoice,
            addressPrice: orderData.addressPrice,
        }
        : initialState;
    
    const [state, dispatch] = useReducer(orderReducer, initialStateWithOrderData);

    const actions = useOrderActions(dispatch);
    const value = useMemo(() => ({ state, actions }), [state, actions]);
    console.log('🚀 ~ OrderProvider ~ value:', value);

    return (
        <orderContext.Provider value={value}>
            {children}
        </orderContext.Provider>
    );
};

export { useOrderContext, OrderProvider };