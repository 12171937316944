export const Routes = {
    HOME: '/',
    LOGIN: '/login',
    ORDERS: '/orders',
    ORDERS_UNPAID: '/orders?paymentStatuses=0',
    ORDERS_LOW_RATING: '/orders?rates=1%2C2%2C3',
    ORDERS_CANCELLED: '/orders?statuses=cancelled',
    ORDERS_DELETED: '/orders?statuses=deleted',
    CUSTOMERS: '/customers',
    CUSTOMERS_DETAILS: '/customers/details',
    DASHBOARD: '/dashboard',
    PARTNERS: '/partners',
    PARTNER_DETAILS: '/partners/details',
    PARTNER_EDIT: '/partners/edit',
    PARTNER_ADD: '/partners/add',
    ORDERS_ADD: '/orders/add',
    ORDER_DETAILS: '/orders/details',
    ORDER_EDIT: '/orders/edit',
    ORDER_COPY: '/orders/copy',
    REVIEWS: '/reviews',
    PROMOCODES: '/promocodes',
    PROMOCODES_ADD: '/promocodes/add',
    PROMOCODE_DETAILS: '/promocodes/details',
    PROMOCODE_EDIT: '/promocodes/edit',
    SERVICES: '/services',
    SERVICES_ADD: '/services/add',
    SERVICE_DETAILS: '/services/details',
    SERVICE_EDIT: '/services/edit',
    SERVICE_TYPES: '/services/types',
    SERVICE_TYPES_ADD: '/services/types/add',
    SERVICE_TYPE_DETAILS: '/services/types/details',
    SERVICE_TYPE_EDIT: '/services/types/edit',
    QUESTIONS: '/questions',
    QUESTIONS_ADD: '/questions/add',
    QUESTION_TYPES_ADD: '/questions/types/add',
    QUESTION_TYPES: '/questions/types',
    QUESTION_TYPE_DETAILS: '/questions/types/details',
    QUESTION_TYPE_EDIT: '/questions/types/edit',
    QUESTION_EDIT: '/questions/edit',
    WAREHOUSE: '/warehouse',
    MESSAGES: '/messages',
    CERTIFICATES: '/certificates',
    ANALYTICS: '/analytics',
    TUTORIALS: '/tutorials',
    AGREEMENTS: '/agreements',
    AGREEMENTS_PARTNERS: '/agreements/partners',
    SEO: '/seo',
    LOGOUT: '/logout',
    CALENDAR: '/calendar',
    SEO_DISTRICTS: '/seo/districts',
    SEO_DISTRICTS_ADD: '/seo/districts/add',
    SEO_STATIC_PAGES: '/seo/staticPages',
    AGREEMENTS_ADD: '/agreements/add',
    AGREEMENTS_EDIT: '/agreements/edit',
    SEO_ORDER_PAGES: '/seo/orderPages',
    SEO_STATIC_PAGES_ADD: '/seo/staticPages/add',
    SEO_ORDER_PAGES_ADD: '/seo/orderPages/add',
    SEO_ORDER_PAGE_EDIT: '/seo/orderPages/edit',
    MOBILE_APPS: '/mobileApps',
};