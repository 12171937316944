import { actionSeoOrderPageAddTypes } from 'constants/actionSeoOrderPageAddTypes';

export const setShowLoading = (dispatch, payload) =>
    dispatch({ type: actionSeoOrderPageAddTypes.SET_SHOW_LOADING, payload });

export const setShowMainError = (dispatch, payload) =>
    dispatch({ type: actionSeoOrderPageAddTypes.SET_SHOW_MAIN_ERROR, payload });

export const setStatus = (dispatch, payload) =>
    dispatch({ type: actionSeoOrderPageAddTypes.SET_STATUS, payload });

export const setSlug = (dispatch, payload) =>
    dispatch({ type: actionSeoOrderPageAddTypes.SET_SLUG, payload });

export const setShowErrors = (dispatch, payload) =>
    dispatch({ type: actionSeoOrderPageAddTypes.SET_SHOW_ERRORS, payload });

export const setPageTitle = (dispatch, payload) =>
    dispatch({ type: actionSeoOrderPageAddTypes.SET_PAGE_TITLE, payload });

export const setMetaDescription = (dispatch, payload) =>
    dispatch({ type: actionSeoOrderPageAddTypes.SET_META_DESCRIPTION, payload });

export const setH1 = (dispatch, payload) =>
    dispatch({ type: actionSeoOrderPageAddTypes.SET_H1, payload });

export const setContent = (dispatch, payload) =>
    dispatch({ type: actionSeoOrderPageAddTypes.SET_CONTENT, payload });

export const setSeoOrderPageData = (dispatch, payload) =>
    dispatch({ type: actionSeoOrderPageAddTypes.SET_SEO_ORDER_PAGE_DATA, payload });

export const setServiceType = (dispatch, payload) =>
    dispatch({ type: actionSeoOrderPageAddTypes.SET_SERVICE_TYPE, payload });

export const setShowServiceTypeError = (dispatch, payload) =>
    dispatch({ type: actionSeoOrderPageAddTypes.SET_SHOW_SERVICE_TYPE_ERROR, payload });

export const setService = (dispatch, payload) =>
    dispatch({ type: actionSeoOrderPageAddTypes.SET_SERVICE, payload });

export const setServicesToChoose = (dispatch, payload) =>
    dispatch({ type: actionSeoOrderPageAddTypes.SET_SERVICES_TO_CHOOSE, payload });