import { actionPartnerAddTypes } from 'constants/actionPartnerAddTypes';

export const initialState = {
    showLoading: false,
    language: null,
    status: 0,
    name: null,
    surname: null,
    middleName: null,
    referralCode: null,
    dateOfBirth: null,
    documentNumber: null,
    documentValidDate: null,
    documentIssueDate: null,
    personalNumber: null,
    personalTaxNumber: null,
    documentFrontFile: null,
    documentFrontImageFile: null,
    documentBackFile: null,
    documentBackImageFile: null,
    documentAdditionalFile: null,
    documentAdditionalImageFile: null,
    addressLabel: null,
    address: {
        latitude: null,
        longitude: null,
        city: null,
        postcode: null,
        street: null,
        house: null,
        apartment: null,
    },
    activePolygons: [],
    iban: null,
    bic: null,
    bankName: null,
    email: null,
    phone: null,
    password: null,
    repeatPassword: null,
    tShirtSize: 'm',
    avatarFile: null,
    avatarImage: null,
    socialNetworks: null,
    errors: {
        main: false,
        name: false,
        surname: false,
        dateOfBirth: false,
        documentNumber: false,
        documentIssueDate: false,
        documentValidDate: false,
        personalNumber: false,
        documentFront: false,
        documentBack: false,
        documentAdditional: false,
        iban: false,
        bic: false,
        bankName: false,
        apartment: false,
        address: false,
        activePolygons: false,
        email: false,
        phone: false,
        password: false,
        repeatPassword: false,
        avatar: false,
        serviceTypes: false,
    },
    serviceTypes: [],
};

export const partnerAddReducer = (state, action) => {
    switch (action.type) {
        default:
            return state;
        case actionPartnerAddTypes.SET_SHOW_LOADING:
            return { ...state, showLoading: action.payload };
        case actionPartnerAddTypes.SET_STATUS:
            return { ...state, status: action.payload };
        case actionPartnerAddTypes.SET_LOCATION:
            return { ...state, location: action.payload };
        case actionPartnerAddTypes.SET_NAME:
            return { ...state, name: action.payload };
        case actionPartnerAddTypes.SET_SURNAME:
            return { ...state, surname: action.payload };
        case actionPartnerAddTypes.SET_MIDDLE_NAME:
            return { ...state, middleName: action.payload };
        case actionPartnerAddTypes.SET_REFERRAL_CODE:
            return { ...state, referralCode: action.payload };
        case actionPartnerAddTypes.SET_DATE_OF_BIRTH:
            return { ...state, dateOfBirth: action.payload };
        case actionPartnerAddTypes.SET_DOCUMENT_NUMBER:
            return { ...state, documentNumber: action.payload };
        case actionPartnerAddTypes.SET_DOCUMENT_ISSUE_DATE:
            return { ...state, documentIssueDate: action.payload };
        case actionPartnerAddTypes.SET_DOCUMENT_VALID_DATE:
            return { ...state, documentValidDate: action.payload };
        case actionPartnerAddTypes.SET_PERSONAL_NUMBER:
            return { ...state, personalNumber: action.payload };
        case actionPartnerAddTypes.SET_PERSONAL_TAX_NUMBER:
            return { ...state, personalTaxNumber: action.payload };
        case actionPartnerAddTypes.SET_DOCUMENT_FRONT_IMAGE_FILE:
            return { ...state, documentFrontImageFile: action.payload };
        case actionPartnerAddTypes.SET_DOCUMENT_FRONT_FILE:
            return { ...state, documentFrontFile: action.payload };
        case actionPartnerAddTypes.SET_DOCUMENT_BACK_FILE:
            return { ...state, documentBackFile: action.payload };
        case actionPartnerAddTypes.SET_DOCUMENT_BACK_IMAGE_FILE:
            return { ...state, documentBackImageFile: action.payload };
        case actionPartnerAddTypes.SET_DOCUMENT_ADDITIONAL_FILE:
            return { ...state, documentAdditionalFile: action.payload };
        case actionPartnerAddTypes.SET_DOCUMENT_ADDITIONAL_IMAGE_FILE:
            return { ...state, documentAdditionalImageFile: action.payload };
        case actionPartnerAddTypes.SET_IBAN:
            return { ...state, iban: action.payload };
        case actionPartnerAddTypes.SET_BIC:
            return { ...state, bic: action.payload };
        case actionPartnerAddTypes.SET_BANK_NAME:
            return { ...state, bankName: action.payload };
        case actionPartnerAddTypes.SET_ADDRESS:
            return {
                ...state,
                address: {
                    ...state.address,
                    ...action.payload,
                }
            };
        case actionPartnerAddTypes.SET_ACTIVE_POLYGONS:
            return { ...state, activePolygons: action.payload };
        case actionPartnerAddTypes.SET_TSHIRT_SIZE:
            return { ...state, tShirtSize: action.payload };
        case actionPartnerAddTypes.SET_EMAIL:
            return { ...state, email: action.payload };
        case actionPartnerAddTypes.SET_PASSWORD:
            return { ...state, password: action.payload };
        case actionPartnerAddTypes.SET_REPEAT_PASSWORD:
            return { ...state, repeatPassword: action.payload };
        case actionPartnerAddTypes.SET_PHONE:
            return { ...state, phone: action.payload };
        case actionPartnerAddTypes.SET_SOCIAL_NETWORKS:
            return { ...state, socialNetworks: action.payload };
        case actionPartnerAddTypes.SET_AVATAR_FILE:
            return { ...state, avatarFile: action.payload };
        case actionPartnerAddTypes.SET_AVATAR_IMAGE:
            return { ...state, avatarImage: action.payload };
        case actionPartnerAddTypes.SET_SERVICE_TYPE:
            const { serviceTypes } = state;
            const serviceType = action.payload;
            const updatedServiceTypes = serviceTypes.includes(serviceType)
                ? serviceTypes.filter((type) => type !== serviceType) // Удаляем, если уже есть
                : [...serviceTypes, serviceType]; // Добавляем, если отсутствует

            return {
                ...state,
                serviceTypes: updatedServiceTypes,
            };
        case actionPartnerAddTypes.SET_SHOW_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    ...action.payload,
                },
            };
        case actionPartnerAddTypes.SET_PARTNER_DATA:
            return {
                ...state,
                id: action.payload.id,
                status: action.payload.status,
                name: action.payload.name,
                surname: action.payload.surname,
                middleName: action.payload.middleName,
                referralCode: action.payload.referralCode,
                tShirtSize: action.payload.tShirtSize,
                dateOfBirth: action.payload.dateOfBirth,
                socialNetworks: action.payload.socialNetworks,
                documentNumber: action.payload.documentNumber,
                documentIssueDate: action.payload.documentIssueDate,
                documentValidDate: action.payload.documentValidDate,
                personalNumber: action.payload.personalNumber,
                personalTaxNumber: action.payload.personalTaxNumber,
                iban: action.payload.bankIban,
                bic: action.payload.bankBicCode,
                bankName: action.payload.bankName,
                activePolygons: action.payload.polygons,
                addressLabel: action.payload.address,
                serviceTypes: action.payload.serviceTypesIds,
                phone: action.payload.phone,
                email: action.payload.email,
            }
    }
};